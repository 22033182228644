//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../components/layout'
import ContactLeadForm from '../components/marketing/contactLeadForm'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div class="flex-grow-1">
        <Helmet
          title="Storylava - Request Invite"
          meta={[
            {
              name: 'description',
              content:
                'Request access to see how Storylava can help you can help you drive your business forward with rich visuals for selling',
            },
          ]}
        />
        <ContactLeadForm
          context="creative"
          leadSource="creative"
          title="Let's get started"
          subtitle="Storylava is currently available with an invite — and we're constantly sending new ones. Get yours below."
        />
      </div>
    )
  }
}
